var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _vm.loading
        ? _c(
            "v-card",
            {
              staticClass: "d-flex justify-center mt-4",
              attrs: { height: "400", tile: "" }
            },
            [
              _c(
                "div",
                { staticClass: "my-auto" },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      indeterminate: "",
                      size: "200",
                      width: "5",
                      color: "primary"
                    }
                  }),
                  _c("h4", { staticClass: "mt-4 font-weight-light" }, [
                    _vm._v("Loading templates ...")
                  ])
                ],
                1
              )
            ]
          )
        : _c(
            "v-card",
            { staticClass: "mt-4", attrs: { tile: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "" } },
                [
                  _c(
                    "v-toolbar-title",
                    [
                      _vm._v(" Templates "),
                      _c(
                        "v-chip",
                        { attrs: { color: "secondary", small: "" } },
                        [_vm._v(_vm._s(_vm.allTemplates.length))]
                      )
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _vm.filtered
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "primary--text",
                          attrs: {
                            small: "",
                            color: "secondary lighten-3",
                            link: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.clearFilter($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "primary--text",
                              attrs: { small: "" }
                            },
                            [_vm._v("mdi-close")]
                          ),
                          _vm._v("Clear Filter ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "500" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { icon: "" } },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-filter")])],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.filterDialog,
                        callback: function($$v) {
                          _vm.filterDialog = $$v
                        },
                        expression: "filterDialog"
                      }
                    },
                    [
                      _vm.filterDialog
                        ? _c("filter-dialog", {
                            attrs: { filter: _vm.filter },
                            on: {
                              close: function($event) {
                                _vm.filterDialog = false
                              },
                              "filter-templates": function($event) {
                                return _vm.filterTemplates($event)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.createTemplate($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-text-box-multiple")
                      ]),
                      _vm._v("Create Template ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    attrs: {
                      "item-key": "id",
                      headers: _vm.headers,
                      items: _vm.allTemplates,
                      "items-per-page": 10,
                      loading: _vm.loading,
                      "loading-text": "Retrieving orders ... please wait",
                      "loader-height": 6,
                      "hide-default-footer": _vm.loading
                    },
                    on: { "click:row": _vm.rowClick },
                    scopedSlots: _vm._u([
                      {
                        key: "item.communication_type",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.communicationTypeMap[
                                    item.communication_type
                                  ]
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.language",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.languageMap[item.language]) + " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.body",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("p", { staticClass: "my-4 d-flex column" }, [
                              _vm._v(_vm._s(item.body))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: {
                                  small: "",
                                  color: "error darken-4",
                                  title: "Delete Template"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.localDeleteTemplate(item)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-delete-circle-outline")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  small: "",
                                  color: "primary",
                                  title: "Edit Template"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.editTemplate(item)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-square-edit-outline")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          ref: "dialog",
          attrs: { "max-width": "600" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.dialog
            ? _c("template-dialog", {
                attrs: { item: _vm.template },
                on: {
                  close: function($event) {
                    _vm.dialog = false
                  },
                  save: _vm.saveTemplateLocal
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          ref: "confirm",
          attrs: { "max-width": "400" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _vm.confirmDialog
            ? _c("psi-confirm-dialog", {
                attrs: {
                  title: "Delete Template ID " + _vm.template.id,
                  prompt:
                    "Do you really want to delete the template " +
                    _vm.template.name +
                    "?"
                },
                on: {
                  close: function($event) {
                    _vm.confirmDialog = false
                  },
                  confirm: _vm.confirmDeleteTemplate
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }