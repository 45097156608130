<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card v-if="loading" height="400" class="d-flex justify-center mt-4" tile>
            <div class="my-auto">
                <v-progress-circular indeterminate size="200" width="5" color="primary"></v-progress-circular>
                <h4 class="mt-4 font-weight-light">Loading templates ...</h4>
            </div>
        </v-card>

        <v-card class="mt-4" v-else tile>
            <v-toolbar color="primary" dark>
                <v-toolbar-title>
                    Templates
                    <v-chip color="secondary" small>{{allTemplates.length}}</v-chip>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-chip
                    v-if="filtered"
                    small
                    color="secondary lighten-3"
                    class="primary--text"
                    link
                    @click.stop="clearFilter"
                >
                    <v-icon small class="primary--text">mdi-close</v-icon>Clear Filter
                </v-chip>
                <v-dialog v-model="filterDialog" width="500">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <filter-dialog
                        v-if="filterDialog"
                        @close="filterDialog=false"
                        :filter="filter"
                        @filter-templates="filterTemplates($event)"
                    ></filter-dialog>
                </v-dialog>
                <v-btn color="secondary" @click.stop="createTemplate">
                    <v-icon class="mr-2">mdi-text-box-multiple</v-icon>Create Template
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    item-key="id"
                    :headers="headers"
                    :items="allTemplates"
                    :items-per-page="10"
                    :loading="loading"
                    loading-text="Retrieving orders ... please wait"
                    :loader-height="6"
                    :hide-default-footer="loading"
                    @click:row="rowClick"
                >   
                    <template v-slot:item.communication_type="{item}">
                        {{communicationTypeMap[item.communication_type]}}
                    </template>
                    <template v-slot:item.language="{item}">
                        {{languageMap[item.language]}}
                    </template>
                    <template v-slot:item.body="{item}">
                        <p class="my-4 d-flex column">{{item.body}}</p>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-btn
                            small
                            color="error darken-4"
                            @click.stop="localDeleteTemplate(item)"
                            class="mr-2"
                            title="Delete Template"
                        >
                            <v-icon small>mdi-delete-circle-outline</v-icon>
                        </v-btn>
                        <v-btn
                            small
                            color="primary"
                            @click.stop="editTemplate(item)"
                            title="Edit Template"
                        >
                            <v-icon small>mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" max-width="600" ref="dialog">
            <template-dialog
                :item="template"
                @close="dialog=false;"
                v-if="dialog"
                @save="saveTemplateLocal"
            ></template-dialog>
        </v-dialog>

        <v-dialog v-model="confirmDialog" ref="confirm" max-width="400">
            <psi-confirm-dialog
                v-if="confirmDialog"
                :title=" `Delete Template ID ${template.id}`"
                :prompt="`Do you really want to delete the template ${template.name}?`"
                @close="confirmDialog=false"
                @confirm="confirmDeleteTemplate"
            ></psi-confirm-dialog>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "message-templates",
    components: {
        TemplateDialog: () =>
            import("@components/templates/components/TemplateDialog"),
        FilterDialog: () =>
            import("@components/templates/components/FilterDialog"),
    },
    props: {
        type: {
            type: String,
            required: false,
            default: "",
        },
        communication_type: {
            type: String,
            required: false,
            default: "",
        },
        language: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            filtered: this.type || this.communication_type || this.language,
            filter: {
                type: this.type,
                communication_type: this.communication_type,
                language: this.language
            },
            filterDialog: false,
            dialog: false,
            confirmDialog: false,
            template: null,
            languageMap: {
                "en": "English", 
                "es": "Spanish", 
            },
            communicationTypeMap: {
                "fax": "Fax", 
                "text": "Text", 
                "email": "Email", 
                
            },
            page: {
                title: "Templates",
                icon: "mdi-text-box-multiple",
            },
            breadcrumbs: [
                {
                    text: "Messages",
                    disabled: false,
                    to: "/messages/view",
                },
                {
                    text: "Templates",
                    disabled: true,
                },
            ],
            headers: [
                {
                    text: "Template ID",
                    width: "140",
                    align: "start",
                    sortable: true,
                    value: "id",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Type",
                    align: "start",
                    width: "140",
                    sortable: true,
                    value: "type",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Communication Type",
                    align: "start",
                    width: "140",
                    sortable: true,
                    value: "communication_type",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Language",
                    align: "start",
                    width: "140",
                    sortable: true,
                    value: "language",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Name",
                    align: "start",
                    width: "200",
                    sortable: true,
                    value: "name",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Text",
                    align: "start",
                    sortable: false,
                    value: "body",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Actions",
                    width: "140",
                    sortable: false,
                    value: "actions",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Template", ["loading", "allTemplates"]),
    },
    watch: {
        filter: {
            immediate: true,
            deep: true,
            handler(val) {
                this.filtered =
                    val.type.length > 0 ||
                    val.communication_type.length > 0 ||
                    val.language.length > 0;
            },
        },
    },
    mounted() {
        this.getAllTemplates(this.filter);
    },
    methods: {
        ...mapActions("Template", [
            "getAllTemplates",
            "saveTemplate",
            "deleteTemplate",
        ]),
        filterTemplates(filter) {
            this.filter = filter;
            this.getAllTemplates(this.filter);
        },
        clearFilter() {
            this.filterTemplates({
                type: "",
                communication_type: "",
                language: "",
            });
        },
        localDeleteTemplate(item) {
            this.template = item;
            this.confirmDialog = true;
        },
        confirmDeleteTemplate() {
            this.confirmDialog = false;
            this.deleteTemplate(this.template);
        },
        editTemplate(item) {
            this.template = item;
            this.dialog = true;
        },
        createTemplate() {
            this.template = null;
            this.dialog = true;
        },
        rowClick(item) {
            this.template = item;
            this.dialog = true;
        },
        async saveTemplateLocal(template) {
            const res = await this.saveTemplate(template);
            if ("id" in res) {
                this.$toast({ content: "Template Saved" });
            }
        },
    },
};
</script>

<style scoped>
</style>